import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/TH_Big.jpg"
import TH from "../../../images/TH_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Touching Holiness"
    SecondText="Check out sermons from our Touching Holiness sermon series"
  >
    <SEO title="Sermons - Touching Holiness" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
                <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness Reaching Us"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/holiness-reaching-us"
        date="July 9th, 2023"
        pastor="Scott Trefny"
      />
                <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="The Opposite of Holy"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/opposite-of-holy"
        date="July 2nd, 2023"
        pastor="Pastor Andrew"
      />
            <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Am I Saved?"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/am-i-saved"
        date="June 25th, 2023"
        pastor="Pastor Andrew"
      />
          <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="A Holy Leader"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/a-holy-leader"
        date="June 18th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Time to Grow Up"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/grow-up"
        date="June 11th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holy Suffering"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/holy-suffering"
        date="June 4th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="The Blessing"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/the-blessing"
        date="May 28th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness in Marriage"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/holiness-in-marriage"
        date="May 21st, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness in the Workplace"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/holiness-in-the-workplace"
        date="May 14th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness in Politics"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/holiness-in-politics"
        date="May 7th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Rejected, but Accepted"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/rejected-but-accepted"
        date="April 30th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Truth and Love"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/truth-and-love"
        date="April 23rd, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Increasingly Holy"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/increasingly-holy"
        date="April 16th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Alive"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/alive"
        date="April 9th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="The Introduction"
        sermonSeries="Touching Holiness"
        sermonLink="/phoenix/sermons/the-introduction"
        date="April 2nd, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/phoenix/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
